import React, { useEffect, useRef, useState } from "react";
import styles from "./index.module.less";
import { Button } from "antd";
import { useRouter } from "next/router";
import OurModal from "@/components/Modal";
import { useTranslation } from "next-i18next";
import { useConfigProvider } from "@/context/ConfigProvider";
import { capitalizeFirstLetter } from "@/lib/hooks/utils/utils";
import { largetalizeFirstLetter } from "@/lib/hooks/utils/utils";
import dynamic from "next/dynamic";
const CustomSelect = dynamic(() => import("./custom-select/index"), {
  ssr: false,
});

const CompetionHead = ({ type }: { type: number }) => {
  const { t } = useTranslation();

  const router = useRouter();

  const curPlat = useRef<string>("");
  const subPlat = useRef<string>("");
  const [curPlatSta, setcurPlatSta] = useState<string>(null);
  const [subPlatSta, setsubPlatSta] = useState<string>(null);
  const { isMobile } = useConfigProvider();
  const handleSelect = (e: any, type: number) => {
    if (type === 1) {
      curPlat.current = e;
      setcurPlatSta(e);
    } else {
      subPlat.current = e;
      setsubPlatSta(e);
    }

    if (isMobile) {
      if (type == 1) {
        if (subPlat.current) {
          router.push(
            `/compare/${largetalizeFirstLetter(e)}-vs-${largetalizeFirstLetter(
              subPlatSta
            )}`
          );
        } else {
          router.push(`/compare/${largetalizeFirstLetter(e)}-alternatives`);
        }
      } else {
        if (curPlat.current) {
          router.push(
            `/compare/${largetalizeFirstLetter(
              curPlatSta
            )}-vs-${largetalizeFirstLetter(e)}`
          );
        } else {
          router.push(`/compare/${largetalizeFirstLetter(e)}-alternatives`);
        }
      }
    }
  };
  const handleConfirm = () => {
    if (curPlatSta && subPlatSta) {
      router.push(
        `/compare/${largetalizeFirstLetter(
          curPlatSta
        )}-vs-${largetalizeFirstLetter(subPlatSta)}`
      );
    } else if (curPlat.current) {
      router.push(
        `/compare/${largetalizeFirstLetter(curPlatSta)}-alternatives`
      );
    } else if (subPlat.current) {
      router.push(
        `/compare/${largetalizeFirstLetter(subPlatSta)}-alternatives`
      );
    }
  };
  const [isOpenModal, setIsModalOpen] = useState(false);
  const getThiredPath = () => {
    if (type == 0) {
      return "compare";
    } else if (type == 1) {
      return Object.values(router.query)[0] + "-alternatives";
    } else {
      return Object.values(router.query)[0];
    }
  };

  useEffect(() => {
    if (router.pathname.includes("-alternatives")) {
      setcurPlatSta(router.query.slug as string);
      curPlat.current = router.query.slug as string;
    } else if (router.pathname.includes("-vs-")) {
      const regex = /\/([a-zA-Z0-9-]+)-vs-([a-zA-Z0-9-]+)/;
      const matches = router.asPath.match(regex);
      if (matches) {
        curPlat.current = matches[1];
        subPlat.current = matches[2];
        setcurPlatSta(matches[1]);
        setsubPlatSta(matches[2]);
      }
    }
  }, []);
  return (
    <div className={styles.container}>
      <div className={styles.head}>
        <div className={styles.head_nav}>
          <a href={process.env.NEXT_APP_WEB_HOST + "/"} target="_blank">
            Home
          </a>
          <span>{">"}</span>
          <a href={process.env.NEXT_APP_WEB_HOST + "/compare"} target="_blank">
            Compare
          </a>
          <span>{">"}</span>
          <a
            href={process.env.NEXT_APP_WEB_HOST + "/compare/" + getThiredPath()}
          >
            {getThiredPath()}
          </a>
        </div>
        <h1>
          {type == 0
            ? t("Top OnlyFans Competitors for Content Creators")
            : type == 1
            ? `${t("Compare OnlyFans Similar Sites").replace(
                "xxx1",
                capitalizeFirstLetter(router.query.slug as string)
              )}`
            : `${t("Compare OnlyFans and Fansly")
                .replace("xxx1", capitalizeFirstLetter(subPlatSta))
                .replace("xxx2", capitalizeFirstLetter(curPlatSta))}`}
        </h1>
        {/* `${t(
                "Fansly: A Website Like OnlyFans. Compare the Differences and Find the Best Platform for You."
              )
                .replace("xxx1", subPlatSta)
                .replace("xxx2", curPlatSta)}` */}
        <p>
          {type == 0
            ? t(
                "What are websites like OnlyFans? Select platforms and criteria to compare."
              )
            : type == 1
            ? t(
                "What are websites like OnlyFans? Select platforms and criteria to compare."
              ).replace("xxx1", capitalizeFirstLetter(curPlatSta))
            : `${t(
                "Compare the Differences and Find the Best Platform for You"
              )}`}
        </p>
        <div className={styles.Selects}>
          <CustomSelect
            onSelect={(e) => handleSelect(e, 1)}
            onOpenModal={() => setIsModalOpen(true)}
            value={curPlatSta}
          ></CustomSelect>
          <span>vs</span>
          <CustomSelect
            onSelect={(e) => handleSelect(e, 2)}
            onOpenModal={() => setIsModalOpen(true)}
            value={subPlatSta}
          ></CustomSelect>
          <Button type="primary" onClick={handleConfirm}>
            {t("Compare Now")}
          </Button>
        </div>
      </div>
      <OurModal
        isModalOpen={isOpenModal}
        handleCancel={() => setIsModalOpen(false)}
        width={350}
        zIndex={1000000}
      >
        <div className={styles.modal}>
          <div>{t("Thank you for your submission")}</div>
          <div>
            {t(
              "We have received your platform submission, and we will include the platform as soon as possible after review. Thank you for your operation ~"
            )}
          </div>
          <div onClick={() => setIsModalOpen(false)}>{t("Confirm")}</div>
        </div>
      </OurModal>
    </div>
  );
};

export default React.memo(CompetionHead);
